import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'
import { Config } from '@chegg-wt/core/config'
import { FooterColumn } from '@chegg-wt/core/types/core'

export const footerLinkColumns: FooterColumn[] = [
  {
    title: 'Company',
    links: [
      {
        name: 'Blog',
        url: `${Config.baseUrl}/blog`,
      },
      { name: 'Chegg Inc.', url: Config.cheggEnvUrl },
      { name: 'Contact Us', url: supportPageLinks.bibme },
      { name: 'Support', url: supportPageLinks.bibme },
      { name: 'Advertise with us', url: Config.advertiseWithUsUrl },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        name: 'BibMe Plus',
        url: `${Config.baseUrl}/upgrade`,
      },
      {
        name: 'Citation Guides',
        url: `${Config.baseUrl}/citation-guide`,
      },
      {
        name: 'APA',
        url: `${Config.baseUrl}/apa`,
      },
      {
        name: 'MLA',
        url: `${Config.baseUrl}/mla`,
      },
      {
        name: 'Chicago Style',
        url: `${Config.baseUrl}/chicago`,
      },
    ],
  },
  {
    title: 'Follow Us',
    links: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/bibme/',
        rel: 'nofollow',
      },
      { name: 'Twitter', url: 'https://twitter.com/bibme', rel: 'nofollow' },
    ],
  },
  {
    title: 'Business',
    links: [
      {
        name: 'Terms of Use',
        url: `${Config.cheggEnvUrl}/termsofuse`,
      },
      {
        name: 'Global Privacy Policy',
        url: `${Config.cheggEnvUrl}/privacypolicy`,
      },
      {
        name: 'Cookie Notice',
        url: `${Config.cheggEnvUrl}/cookie-notice`,
      },
      {
        name: 'Your Privacy Choices',
        url: `https://privacyportal.onetrust.com/webform/a21a74f4-2f93-44a2-a887-302a9213c997/06b040b7-49eb-464a-b19a-ea35c85c79fc`,
      },
      {
        name: 'DO NOT SELL MY INFO',
        id: 'ot-sdk-btn',
        className: 'ot-sdk-show-settings',
        url: '#',
      },
    ],
  },
]
